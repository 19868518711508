import React, { Component } from "react";
import FooterLinks from "./footer-links";
class Footer extends Component {
  state = {
    links1: [
      { link: "#", title: "About Us" },
      { link: "#", title: "Media & Press" },
      { link: "#", title: "Career" },
      { link: "#", title: "Blog" },
    ],
    links2: [
      { link: "#", title: "Pricing" },
      { link: "#", title: "For Marketing" },
      { link: "#", title: "For CEOs" },
      { link: "#", title: "For Agencies" },
      { link: "#", title: "Our Apps" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
