import React, { Component } from "react";
import Footer from "../../components/Footer/footer";
import Section from "./section";

class Index1 extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }
  render() {
    return (
      <React.Fragment>
       

        {/* Importing section */}
        <Section />

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index1;
