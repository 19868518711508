import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
//Import Images
import bg2 from "../../assets/images/bg-2.png";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="home" style={{ background: `url(${bg2}) center center` }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="hero-wrapper text-center mb-4">
                  <h3 id="about">About Us - South Africa</h3>
                  <p>
                    QikTruck South Africa collects and delivers heavy goods like Furniture,
                    Equipment or Rubble/Waste using our affordable decentralised
                    local pickup trucks. <br />
                    <br />
                    Simply calculate your costs and order a truck with a driver
                    and a helper to come collect your movable goods.
                  </p>
                  <hr />

                  <h3 id="howitworks">How it works</h3>
                  <ul>
                    <li>
                      <p>Get an  <a href="/">instant free quotation</a>.</p>
                    </li>
                    <li>
                      <p>Submit an order.</p>
                    </li>
                    <li>
                      <p>
                        A driver, a helper and a truck will come assist with
                        your move
                      </p>
                    </li>
                    <li>
                      <p>Pay as the job starts or after. Online, Cash, Bank tranfer.</p>
                    </li>
                  </ul>
                  <hr />
                  <h3 id="contact">Contact Us</h3>
                  <p>
                    If you have any questions about this service, You can
                    contact us:
                  </p>
                  <ul>
                    <li>
                      <p>
                        By email:{" "}
                        <a href="mailto:support@qiktruck.co.za">
                          support@qiktruck.co.za
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        By phone number:{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://wa.me/+27663784460"
                        >
                          +27 66 378 4460
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
