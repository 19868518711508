import CurrencyFormat from "react-currency-format";
import React, { memo, useEffect, useState } from "react";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../Constants";
import {
  Area,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ComposedChart,
  Line,
} from "recharts";
const baseURL = constants.baseURL;

function MyComponent() {
  const [vehiclehireprices, setVehicleHirePrices] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [daily, setVdaily] = useState(true);
  const [weekly, setVweekly] = useState(false);
  const [monthly, setVmonthly] = useState(false);
  const [insurance, setVinsurance] = useState(true);
  const [tracking, setVtracking] = useState(true);
  const [gitcover, setVgitcover] = useState(false);
  const [gitcost, setVgitcost] = useState(false);
  const [vehicletypes, setVehicleTypes] = useState([]);
  // const [vehicleOwners, setVehicleOwners] = useState([]);
  const fetchVehiclesOwners = (mounted) => {
    fetch(baseURL + "/vehicle-owners", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: cookie.load("hq-id-qt"),
      },
    })
      .then((data) => data.json())
      .then((json) => {
        if (mounted) {
          // setVehicleOwners(json);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchVehiclesDrivers = (mounted) => {
    fetch(
      baseURL +
        "/vehicles?vehiclestatus=availableblockedrestingprocessingin-review",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookie.load("hq-id-qt"),
        },
      }
    )
      .then((data) => data.json())
      .then((json) => {
        if (mounted) {
          setVehicles(json);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchTruckHirePrices = (mounted) => {
    fetch(baseURL + "/truck-hire-prices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: cookie.load("hq-id-qt"),
      },
    })
      .then((data) => data.json())
      .then((json) => {
        console.log(JSON.stringify(json.map((f) => f.tracker)));
        if (mounted) {
          const vehicleid = "vehicleid";
          const arrayUniqueByEmail = [
            ...new Map(json.map((item) => [item[vehicleid], item])).values(),
          ];

          setVehicleHirePrices(arrayUniqueByEmail);
          const key = "trucktype";

          const arrayUniqueByKey = [
            ...new Map(json.map((item) => [item[key], item])).values(),
          ].map((t) => t.trucktype);
          console.log(arrayUniqueByKey);
          setVehicleTypes(arrayUniqueByKey);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getImage = (trucktype) => {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchTruckHirePrices(mounted);
    fetchVehiclesDrivers(mounted);
    fetchVehiclesOwners(mounted);
    return () => {};
  }, []);

  return (
    <div style={{ display: "inline-block" }}>
      <div className="toggler">
        <span
          className={insurance ? "selectedv" : ""}
          onClick={() => setVinsurance(!insurance)}
        >
          insurance
        </span>
        <span
          className={gitcost ? "selectedv" : ""}
          onClick={() => setVgitcost(!gitcost)}
        >
          gitcost
        </span>
        <span
          className={gitcover ? "selectedv" : ""}
          onClick={() => setVgitcover(!gitcover)}
        >
          gitcover
        </span>
        <span
          className={daily ? "selectedv" : ""}
          onClick={() => setVdaily(!daily)}
        >
          daily
        </span>
        <span
          className={weekly ? "selectedv" : ""}
          onClick={() => setVweekly(!weekly)}
        >
          weekly
        </span>
        <span
          className={monthly ? "selectedv" : ""}
          onClick={() => setVmonthly(!monthly)}
        >
          monthly
        </span>
        <span
          className={tracking ? "selectedv" : ""}
          onClick={() => setVtracking(!tracking)}
        >
          tracking
        </span>
      </div>
      <p></p>
      <p></p>
      <p></p>
      <div style={{ marginTop: 90 }}>
        {vehicletypes.map((t) => (
          <div className="vehiclehire-types-list">
            <div className="vehiclehire-types-img">
              <img
                src={getImage(t)}
                alt={t}
                className="vehiclehire-types-type"
              />
            </div>
            <div className="vehiclehire-types-info 1">
              <div className="vehiclehire-types-stat">
                {vehiclehireprices?.filter((f) => f.trucktype === t)?.length}/
                {vehicles?.filter((f) => f.trucktype === t)?.length} :
                {Math.floor(
                  (vehiclehireprices?.filter((f) => f.trucktype === t)?.length /
                    vehicles?.filter((f) => f.trucktype === t)?.length) *
                    100
                )}
                %
              </div>
              <div className="vehiclehire-types-stat">
              <b>daily:{" "}</b>
                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.daily || 0), 0) /
                    vehiclehireprices?.filter((f) => f.trucktype === t)?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>weekly:{" "}</b>
                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.weekly || 0), 0) /
                    vehiclehireprices?.filter((f) => f.trucktype === t)?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>monthly:{" "}</b>
                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.monthly || 0), 0) /
                    vehiclehireprices?.filter((f) => f.trucktype === t)?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>tracking:{" "}</b>
                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.tracking || 0), 0) /
                    vehiclehireprices?.filter(
                      (f) => f.trucktype === t && f.tracking > 0
                    )?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>insurance:{" "}</b>
                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.insurance || 0), 0) /
                    vehiclehireprices?.filter(
                      (f) => f.trucktype === t && f.insurance > 0
                    )?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>gitcost:{" "}</b>

                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.gitcost || 0), 0) /
                    vehiclehireprices?.filter(
                      (f) => f.trucktype === t && f.gitcost > 0
                    )?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
              <b>gitcover:{" "}</b>

                <CurrencyFormat
                  value={
                    vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .reduce((a, b) => a + (b.gitcover || 0), 0) /
                    vehiclehireprices?.filter(
                      (f) => f.trucktype === t && f.gitcover > 0
                    )?.length
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
            </div>
            <div className="vehiclehire-types-info 2">
              <div className="vehiclehire-types-stat"></div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.daily)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.daily)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.weekly)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.weekly)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.monthly)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.monthly)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t && f.tracking > 0)
                      .map((o) => o.tracking)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.tracking)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t && f.insurance > 0)
                      .map((o) => o.insurance)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.insurance)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t && f.gitcost > 0)
                      .map((o) => o.gitcost)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.gitcost)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
              <div className="vehiclehire-types-stat">
                <CurrencyFormat
                  value={Math.min(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t && f.gitcover > 0)
                      .map((o) => o.gitcover)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />{" "}
                /
                <CurrencyFormat
                  value={Math.max(
                    ...vehiclehireprices
                      ?.filter((f) => f.trucktype === t)
                      .map((o) => o.gitcover)
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </div>
            </div>
            <div className="vehiclehire-types-graph">
              <ResponsiveContainer height={300} width={"100%"}>
                <ComposedChart
                  width={730}
                  height={250}
                  data={vehiclehireprices
                    ?.filter((f) => f.trucktype === t)
                    ?.map((m) => ({
                      name: m.vehicleid,
                      insurance: m.insurance,
                      tracking: m.tracking,
                      daily: m.daily,
                      weekly: m.weekly,
                      monthly: m.monthly,
                      gitcover: m.gitcover,
                      gitcost: m.gitcost,
                    }))}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <CartesianGrid stroke="#f5f5f5" />
                  {insurance && (
                    <Area
                      type="monotone"
                      dataKey="insurance"
                      fill="#8884d8"
                      stroke="#8884d8"
                    />
                  )}
                  {daily === true && (
                    <Bar dataKey="daily" barSize={20} fill="lightblue" />
                  )}
                  {weekly === true && (
                    <Bar dataKey="weekly" barSize={20} fill="lightgreen" />
                  )}
                  {monthly === true && (
                    <Bar dataKey="monthly" barSize={20} fill="orange" />
                  )}
                  {tracking === true && (
                    <Line type="monotone" dataKey="tracking" stroke="#ff7300" />
                  )}
                  {gitcost === true && (
                    <Line type="monotone" dataKey="gitcost" stroke="green" />
                  )}
                  {gitcover === true && (
                    <Line type="monotone" dataKey="gitcover" stroke="purple" />
                  )}
                  <defs>
                    <linearGradient
                      id="colorOrdersFinished"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="green" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="colorOrdersStopped"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="red" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="red" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        ))}
      </div>

      <div className="section-list">
        {vehiclehireprices?.map((o, index) => {
          return (
            <div
              key={index}
              className={
                "truck-hire-item " +
                vehicles.find((v) => v.vehicleid === o.vehicleid)
                  ?.vehiclestatus +
                " " +
                o.vehicleid
              }
            >
              <br />
              <span className="price-field">
                {vehiclehireprices?.length - index}
              </span>
              <img src={getImage(o.trucktype)} alt={o.trucktype} />
              <span className="price-field">{o.collectiondate}</span>
              <span className="price-field">
                {o.email ||
                  vehicles.find((v) => v.vehicleid === o.vehicleid)?.email}
                , {o.mobilenumber}, {o.fullname}
              </span>
              <span className="price-field">
                {o.homelat}, {o.homelng}
              </span>
              <span className="price-field">|Daily - {o.daily}</span>
              <span className="price-field">|Weekly - {o.weekly}</span>
              <span className="price-field">|Monthly - {o.monthly}</span>
              <span className="price-field">
                |Insurance {o.insurer}, {o.insurance}
              </span>
              <span className="price-field">
                |GIT {o.gitter}, {o.gitcost},, {o.gitcover}
              </span>
              <span className="price-field">
                |Tracker {o.tracker}, {o.tracking}
              </span>
              {o.vehicleid && (
                <>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://qiktruck.co.za/?v=${o.vehicleid}`}
                    className="price-field"
                  >
                    {"<"}book{">"}
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://qiktruck.co.za/vehicle-hire-price-survey?v=${o.vehicleid}&t=${o.trucktype}`}
                    className="price-field"
                  >
                    New price
                  </a>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(MyComponent);
