import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import ServicesList from "../../components/common/ServicesList";

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import constants from "../../Constants";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      success: false,
      apierror: "",
      idnumber: "",
      otp: "",
      showPasswordField: false,
    };
  }
  validStepOne() {
    const { idnumber } = this.state;
    if (idnumber === "") return false;
    return true;
  }
  validStepTwo() {
    const { idnumber, otp } = this.state;
    if (idnumber === "" || otp === "") return false;
    return true;
  }
  getAdminOTP() {
    const { idnumber } = this.state;
    this.setState({ loading: true, apierror: "" });
    axios
      .post(baseURL + "/admin-otp", { idnumber })
      .then((response) => {
        this.setState({
          loading: false,
          showPasswordField: true,
        });
      })
      .catch((e) => {
        console.log(e);
        alert(
          "An account error occured please try again or contact your admin.\n Your account may need to be activated first."
        );
        this.setState({
          loading: false,
        });
      });
  }
  validateOTP() {
    const { idnumber, otp } = this.state;
    this.setState({
      loading: true,
      apierror: "",
    });
    axios
      .post(baseURL + "/validate-admin-otp", {
        idnumber,
        otp,
      })
      .then((response) => {
        if (response.status === 200) {
          //set cookie
          console.log(response);
          if (
            response.data?.permissionjson?.includes("superuser") ||
            response.data?.permissionjson?.includes("financeuser")
          ) {
            cookie.save("hq-super-qt", "superuser", {
              path: "/",
              maxAge: 86400,
              secure: true,
            });
          }

          cookie.save("hq-id-qt", response.data["hq-id-qt"], {
            path: "/",
            maxAge: 86400,
            secure: true,
          });
          cookie.save("adminName", response.data.firstname, {
            path: "/",
            maxAge: 86400,
            secure: true,
          });
          this.setState(
            {
              loading: false,
              apierror: "",
            },
            () => {
              window.location.href = "/dashboard";
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }
  render() {
    return (
      <React.Fragment>
        <section className="hero-section" id="home">
          <Container>
            <div>
              <h1>Move anything anytime anywhere</h1>
              <ServicesList />
              <a
                className="from-admin-website"
                href="https://qiktruck.co.za/quote?from=admin-website"
              >
                CUSTOMERS GET QUOTE HERE
                <i className="mdi mdi-arrow-right ml-3"></i>
              </a>

              {this.state.showPasswordField === false && (
                <>
                  <Row>
                    <Col lg={6} sm={6}>
                      <FormGroup>
                        <Label for="idnumber">Reference Code</Label>
                        <Input
                          id="idnumber"
                          name="idnumber"
                          onChange={(e) => {
                            this.setState({ idnumber: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <p>
                    <Button
                      className="float-left mb-5"
                      color="primary"
                      onClick={() => {
                        this.getAdminOTP();
                      }}
                      disabled={!this.validStepOne() || this.state.loading}
                    >
                      Next
                      <i className="mdi mdi-arrow-right ml-2"></i>
                    </Button>
                  </p>
                </>
              )}
              {this.state.showPasswordField && (
                <>
                  <Row
                    onClick={() =>
                      this.setState({
                        showPasswordField: false,
                      })
                    }
                  >
                    <p>
                      <i className="mdi mdi-arrow-left ml-3"></i>
                      <br />
                    </p>
                  </Row>
                  <Row>
                    <Col lg={6} sm={6}>
                      <FormGroup>
                        <Label for="otp">Check your email</Label> (
                        {this.state.idnumber})
                        <Input
                          id="otp"
                          name="otp"
                          placeholder="Enter One Time PIN"
                          type="password"
                          onChange={(e) => {
                            this.setState({ otp: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <p>
                    <Button
                      className="float-left mb-5"
                      color="primary"
                      onClick={() => {
                        this.validateOTP();
                      }}
                      disabled={!this.validStepTwo() || this.state.loading}
                    >
                      Next
                      <i className="mdi mdi-arrow-right ml-2"></i>
                    </Button>
                  </p>
                </>
              )}
              {this.state.loading === false &&
                this.state.apierror.length > 0 && (
                  <p className="error-message">{this.state.apierror}</p>
                )}
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
