import React, { useState } from "react";
import constants from "../../Constants";
import cookie from "react-cookies";
const baseURL = constants.baseURL;
const Authorization = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: cookie.load("hq-id-qt"),
  },
});
function FileUpload(props) {
  const [file, setFile] = useState(null);
  const [uploadstatus, setUploadStatus] = useState(null);
  const [uploading, seUploading] = useState(false);
  const [metadata, setMetadata] = useState({
    title: props.title,
    description: props.description,
    location: props.location,
  });

  const handleMetadataChange = (e) => {
    setMetadata({ ...metadata, [e.target.name]: e.target.value });
  };

  const getPresignedUploadURL = async (file) => {
    console.log("file", file);
    const response = await fetch(baseURL + "/job-file-upload-url", {
      method: "POST",
      body: JSON.stringify({
        file: {
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          originalname: file.name,
          size: file.size,
          type: file.type,
          mimetype: file.mimetype,
          webkitRelativePath: file.webkitRelativePath,
        },
        filetype: file.type,
        metadata,
        orderid: props.orderid,
      }),
      ...Authorization("POST"),
    });

    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`);
    } else {
      addConversation(props.orderid, file.name);
    }
    const text = await response.text();
    return text;
  };
  const addConversation = (orderid, filename) => {
    fetch(baseURL + "/add-conversation", {
      method: "POST",
      body: JSON.stringify({
        orderid,
        message: "Upload started for file :: " + filename,
      }),
      ...Authorization("POST"),
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;
    seUploading(true);
    setUploadStatus(null);
    try {
      const uploadURL = await getPresignedUploadURL(file);

      // Now upload the file to S3 using the pre-signed URL
      const s3UploadResponse = await fetch(uploadURL, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type, // Make sure the content type matches
        },
      });

      if (s3UploadResponse.ok) {
        console.log("File uploaded successfully.");
        props.getOrderFiles(props.orderid);
        props.getOrderConversations(props.orderid);
        setUploadStatus("File uploaded successfully.");
        // Handle the successful upload here (e.g., update the state or notify the user)
      } else {
        console.error("File upload failed.");
        setUploadStatus("File uploaded failed.");
        console.log(s3UploadResponse);
        // Handle the upload failure here (e.g., notify the user)
      }
    } catch (error) {
      console.error("Error during the upload process:", error);
      setUploadStatus("Error uploading file.");
    } finally {
      seUploading(false);
    }
    // POST request to your Node.js server
  };

  // Function to call the backend to get the pre-signed URL

  // Function to handle the file upload upon file selection
  const setFileUpload = async (event) => {
    setFile(event.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit} className={"file-upload-form "}>
      {uploading && (
        <p className="loading">Uploading your file, please wait...</p>
      )}
      <input
        type="file"
        id="fileInput"
        onChange={setFileUpload}
        className="file-picker"
      />
      <input
        name="title"
        placeholder="(edit) Title"
        onChange={handleMetadataChange}
        className="title"
      />
      <textarea
        name="description"
        placeholder="(edit) Description"
        onChange={handleMetadataChange}
      />
      <button
        className="upload-button float-right"
        type="submit"
        disabled={uploading || !file || !metadata.title}
      >
        Upload
      </button>
      <p>
        {uploadstatus && (
          <span className="upload-status red error">{uploadstatus}</span>
        )}
      </p>
    </form>
  );
}

export default FileUpload;
