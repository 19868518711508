import React, { Component } from "react";

import { Col, Row } from "reactstrap";
class MoreInfo extends Component {
  render() {
    return (
      <Row>
        <Col lg={4} md={4} className="move-anything">
          <h3 className="font-18">Furniture Movers</h3>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Home and Office moves.
            </a>
          </p>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Furniture, Home Equipment,
              Personal goods.
            </a>
          </p>
          <p>
            <a href="/furniture-delivery-services?src=landing">
              <i className="mdi mdi-star"></i>Get help to carry and deliver on
              time.
            </a>
          </p>
        </Col>
        <Col lg={4} md={4} className="truck-hire">
          <h3 className="font-18">Goods transportation</h3>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Deliver goods to your Customers, Warehouse, Shop and House on
              time.
            </a>
          </p>
          <p>
            <a href="/bakkie-hire?src=landing">
              <i className="mdi mdi-star"></i>Manufacturers, Retailers, Supply
              shops.
            </a>
          </p>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Short term and Long term contracts
              for scheduled trips.
            </a>
          </p>
        </Col>
        <Col lg={4} md={4} className="move-goods">
          <h3 className="font-18">Schedule trips</h3>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Schedule trips for your goods to
              be delivered anytime.
            </a>
          </p>
          <p>
            <a href="/bakkie-hire?src=landing">
              <i className="mdi mdi-star"></i>Bakkie Hire services from trained and trusted drivers.
            </a>
          </p>
          <p>
            <a href="/truck-hire?src=landing">
              <i className="mdi mdi-star"></i>Insured Truck and driver hire.
            </a>
          </p>
        </Col>
      </Row>
    );
  }
}

export default MoreInfo;
