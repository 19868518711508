import axios from "axios";
import { memo, useEffect, useState, default as React } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  Table,
} from "reactstrap";
import constants from "../../Constants";
const moment = require("moment");
const Authorization = (method) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    authorization: cookie.load("hq-id-qt"),
  },
});
const baseURL = constants.baseURL;

function MyComponent() {
  const [pPaginator, setPPaginator] = useState(0);
  const [driverFocus, setStartPaymentModal] = useState({
    orderid: null,
  });
  const [paymentList, setPaymentList] = useState([]);
  const [orderconversations, setOrderconversations] = useState([]);
  const [orderstatuses, setOrderStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentsordersquotations, setOrdersQuotations] = useState([]);
  const [vehicleDocuments, setVehicleDocuments] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [processingData, setProcessingData] = useState(false);
  const [fixPricingTrip, setFixPricingTrip] = useState(false);
  const [filterPromoCodes, setFilterPromoCodes] = useState([]);
  const [promocodeFilter, setPromocodeFilter] = useState("all");
  const [datefrom, setDateFrom] = useState("2024-07-01");
  const [ordersFilter, filterOrders] = useState({
    filter: "",
  });
  const applyFilterForPromoCodes = (obj) => {
    const promocode = // orderstatus
      promocodeFilter === "all" ? true : obj.promocode === promocodeFilter;
    return promocode;
  };
  const applyFilter = (obj) => {
    let validorder = true;
    let filterValue = ordersFilter.filter.toLowerCase()?.replace(/ /g, "");
    if (ordersFilter?.filter !== "")
      validorder =
        obj?.orderid?.replace(/ /g, "")?.toLowerCase()?.indexOf(filterValue) >
          -1 ||
        obj?.collectiondate?.toLowerCase()?.indexOf(filterValue) > -1 ||
        obj?.idnumber?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.email?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.firstname?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.description
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.promocode?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.mobilenumber
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.sellingprice?.toString()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.source_?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.tripdistance?.toString()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.trips?.toString()?.replace(/ /g, "")?.indexOf(filterValue) > -1 ||
        obj?.trucktype?.toLowerCase()?.replace(/ /g, "")?.indexOf(filterValue) >
          -1 ||
        obj?.driveremail
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.driveridnumber
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.driverfirstname
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.driverlastname
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.drivermobilenumber
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        obj?.licenseplatenumber
          ?.toLowerCase()
          ?.replace(/ /g, "")
          ?.indexOf(filterValue) > -1 ||
        moment(obj?.collectiondate)
          .format("MMMM dddd YYYY h a")
          ?.toLowerCase()
          ?.indexOf(filterValue) > -1 ||
        obj?.licenseplatenumber?.toLowerCase()?.indexOf(filterValue) > -1;
    if (ordersFilter?.payfast === true)
      validorder = obj?.paymentid && !obj?.driverpaid;
    if (ordersFilter?.nopayment === true) validorder = !obj?.paymentid;
    return validorder;
  };
  const getOrderStatuses = (orderid) => {
    axios
      .get(baseURL + "/order-statuses?orderid=" + orderid)
      .then((response) => {
        setOrderStatuses(response.data);
      })
      .catch((e) => {
        console.log("/order-statuseserr", e);
        alert(e);
      });
  };
  const getOrderConversations = (orderid) => {
    axios
      .get(baseURL + "/order-conversations?orderid=" + orderid)
      .then((response) => {
        setOrderconversations(response.data.conversation);
      })
      .catch((e) => {
        console.log("/order-statuses err", e);
        alert(e);
      });
  };

  const getCalculations = (o) => {
    let costoftrip = (o?.tripdistance / 1000) * o?.cpkcents;
    let commission = costoftrip * o?.servicefee;
    let driverpay = costoftrip - commission;
    let vat = commission * 0.15;
    let sellingprice = commission + driverpay + vat;
    let valid = sellingprice?.toFixed(1) === o?.sellingprice?.toFixed(1);
    // console.log("_______________________________________", o);
    // console.log(sellingprice, o?.sellingprice);
    // console.log(o?.trucktype);
    // console.log(o?.cpkcents, o?.tripdistance);
    // console.log("costoftrip", costoftrip);
    // console.log("driverpay", driverpay);
    // console.log("commission", commission);
    // console.log("vat", vat);
    // console.log("sellingprice", sellingprice);
    // console.log(
    //   "valid",
    //   valid
    //     ? valid
    //     : "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
    // );

    if (o?.sellingprice < 0) {
      driverpay = o?.sellingprice;
      commission = o?.sellingprice * -1;
      vat = 0;
    }
    // console.log("_______________________________________");
    return {
      ...o,
      costoftrip,
      commission,
      driverpay,
      vat,
      sellingprice,
      valid,
    };
  };
  const getPricesView = (o) => {
    return (
      <>
        <div>
          {Math.abs(!o?.driverpay || !o?.commission || !o?.vat) ? (
            <i className="mdi mdi-alert orange">vat</i>
          ) : (
            <></>
          )}
        </div>
        <div className={"driver-pay " + (o.driverpay ? "true" : "")}>
          {/* DRIVER PAY */}
          <CurrencyFormat
            value={
              o.driverpay
                ? o.driverpay / 100
                : (o.sellingprice / 100) * (1 - o.servicefee)
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R"}
            decimalScale={2}
            className="float-left"
          />
        </div>
        {o?.source_?.toLowerCase()?.indexOf("mailer")[1] && (
          <i className="mdi mdi-envelope" />
        )}
        <div
          style={{
            marginRight: "20px",
            borderBottom: "2px solid #666666",
          }}
          className={"commission-pay" + (o.commission ? "true" : "")}
        >
          {/* COMMISSION */}
          <CurrencyFormat
            value={
              o.commission
                ? o.commission / 100
                : (o.sellingprice / 100) * o.servicefee
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R"}
            decimalScale={2}
            className="float-left"
          />
        </div>
        <div
          style={{
            marginRight: "20px",
            borderBottom: "2px solid #666666",
          }}
          className={"vat-pay " + (o.vat ? "true" : "")}
        >
          {/*  VAT */}
          <CurrencyFormat
            value={o?.vat / 100}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R"}
            decimalScale={2}
            className="float-left"
            onClick={() => {
              setFixPricingTrip(false);
              setTimeout(() => {
                setFixPricingTrip(o);
              }, 500);
            }}
          />
        </div>
      </>
    );
  };
  const fetchOrdersQuotations = (fromdate) => {
    fromdate = fromdate || datefrom;
    setLoading(true);
    fetch(baseURL + "/stopped-orders-quotations?fromdate=" + fromdate, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: cookie.load("hq-id-qt"),
      },
    })
      .then((data) => data?.json())
      .then((json) => {
        setOrdersQuotations(json);
        let vehicleLists;
        const qvs = json?.reduce(function (p, c) {
          let name = c.ownerdriverid;
          if (!p.hasOwnProperty(name)) {
            p[name] = 0;
          }
          p[name]++;
          return p;
        }, {});
        if (qvs)
          vehicleLists = Object.keys(qvs).map((k) => {
            return { name: k, value: qvs[k] };
          });
        setVehicleList(vehicleLists);
        let uniquePromoCodes = json
          .map((o) => o.promocode)
          .filter((v, i, a) => a.indexOf(v) === i && v !== null && v !== "");
        setFilterPromoCodes(uniquePromoCodes);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const setDriverPaidOrders = () => {
    setProcessingData(true);
    fetch(baseURL + "/payments-driver-paid", {
      method: "POST",
      body: JSON.stringify({
        orderids: paymentList,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: cookie.load("hq-id-qt"),
      },
    })
      .then((json) => {
        fetchOrdersQuotations(datefrom);
        alert("New data is being loaded");
      })
      .catch((e) => {
        console.log("setDriverPaidOrders", e);
      })
      .finally(() => {
        setProcessingData(false);
      });
  };

  const getImage = (trucktype) => {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  };
  useEffect(() => {
    fetchOrdersQuotations();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="filtercenter ">
        {filterPromoCodes?.length > 0 ? (
          filterPromoCodes.map((f) => {
            return (
              <button
                className={
                  "filterpromocode " +
                  (promocodeFilter === f ? "selected mdi mdi-check" : "")
                }
                key={f}
                onClick={() => {
                  setPromocodeFilter(f);

                  let promoDrivers = paymentsordersquotations.filter(
                    (p) =>
                      p.promocode?.toLowerCase() === f?.toLowerCase() &&
                      p?.driverpaid === 0
                  );
                  console.log("promoDrivers", promoDrivers);

                  let drivers = [];
                  for (let i = 0; i < promoDrivers.length; i++) {
                    let driver = promoDrivers[i];
                    let vehicleid = driver?.vehicleid;
                    let driverOrders = paymentsordersquotations.filter(
                      (f) => f.vehicleid === vehicleid
                    );
                    let sellingPriceTotal = driverOrders.reduce(
                      (a, b) => a + b.sellingprice,
                      0
                    );
                    let driverPayTotal = driverOrders.reduce(
                      (a, b) => a + getCalculations(b)?.driverpay,
                      0
                    );
                    let commissionTotal = driverOrders.reduce(
                      // commission
                      (a, b) => a + getCalculations(b)?.commission,
                      0
                    );
                    if (!drivers.find((f) => f.driver === vehicleid)) {
                      drivers.push({
                        driver: vehicleid,
                        sellingPriceTotal,
                        driverPayTotal,
                        commissionTotal,
                        promocode: driver.promocode,
                        orderscount: driverOrders.length,
                        firstname: driver.driverfirstname,
                        lastname: driver.driverlastname,
                      });
                    }
                  }
                  console.log(drivers);
                }}
              >
                {
                  paymentsordersquotations.filter((obj) => obj.promocode === f)
                    .length
                }
                {" - "}
                {f}
              </button>
            );
          })
        ) : (
          <Spinner />
        )}
        <button
          className={
            "filterpromocode " +
            (promocodeFilter === "" || !promocodeFilter
              ? "selected mdi mdi-check"
              : "")
          }
          onClick={() => {
            setPromocodeFilter("");
          }}
        >
          {
            paymentsordersquotations.filter((obj) => obj.promocode === "")
              .length
          }
          {" - "}
          No promocode
        </button>
        <button
          className={
            "filterpromocode " +
            (promocodeFilter === "all" ? "selected mdi mdi-check" : "")
          }
          onClick={() => {
            setPromocodeFilter("all");
          }}
        >
          ALL
        </button>
      </div>
      <Modal
        isOpen={driverFocus.orderid?.length > 0}
        size={"xl"}
        onOpened={() => {
          axios
            .post(
              baseURL + "/vehicle-documents",
              {
                driverprofile: (
                  driverFocus.driverfirstname +
                  " " +
                  driverFocus.driverlastname +
                  " - " +
                  driverFocus.driveridnumber
                )
                  .replace(/ {2}/g, " ")
                  .trim(),
              },
              Authorization("POST")
            )
            .then((repos) => {
              setVehicleDocuments(repos.data);
            });
        }}
      >
        <ModalBody>
          <h6>
            {driverFocus?.driverfirstname?.toUpperCase() +
              " " +
              driverFocus?.driverlastname?.toUpperCase() +
              " - " +
              driverFocus?.driveridnumber?.toUpperCase() +
              " - " +
              driverFocus?.driveremail?.toUpperCase() +
              " - " +
              driverFocus?.licenseplatenumber?.toUpperCase() +
              " - " +
              driverFocus?.drivermobilenumber?.toUpperCase()}{" "}
            <img src={getImage(driverFocus?.trucktype)} alt="" height="25" />
            <div className="driver-prices">
              <div className="stats-holder">
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                    .reduce(
                      (a, b) => a + parseFloat(b.sellingprice / 100 || 0, 0),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left"
                />{" "}
              </div>
              <div className="stats-holder">
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter(
                      (f) =>
                        f.vehicleid === driverFocus.vehicleid &&
                        f.paymentid &&
                        f.driverpaid
                    )
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice / 100) * (1 - b.servicefee) || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left green"
                />{" "}
                paid to driver
              </div>
              <div className="stats-holder">
                (
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter(
                      (f) =>
                        f.vehicleid === driverFocus.vehicleid &&
                        f.paymentid &&
                        !f.driverpaid
                    )
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice / 100) * (1 - b.servicefee) || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left red"
                />
              </div>
              <div className="stats-holder">
                Payfast unpaid
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter(
                      (f) =>
                        f.vehicleid === driverFocus.vehicleid && !f.driverpaid
                    )
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice * (1 - b.servicefee)) / 100 || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left red"
                />
              </div>
              <div className="stats-holder">
                Unpaid to driver
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter(
                      (f) =>
                        f.vehicleid === driverFocus.vehicleid && !f.driverpaid
                    )
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice * b.servicefee) / 100 || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left red"
                />
              </div>
              <div className="stats-holder">
                Unpaid fee{" "}
                <CurrencyFormat
                  value={paymentsordersquotations
                    ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice * b.servicefee) / 100 || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left green"
                />
              </div>
              <div className="stats-holder">
                <CurrencyFormat
                  value={paymentList
                    ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                    .reduce(
                      (a, b) =>
                        a +
                        parseFloat(
                          (b.sellingprice / 100) * (1 - b.servicefee) || 0,
                          0
                        ),
                      0
                    )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"R"}
                  decimalScale={2}
                  className="float-left orange"
                />{" "}
                selected total price
              </div>
            </div>
          </h6>
          <p className="file-preview-parent">
            {vehicleDocuments?.map((m) => (
              <>
                <a
                  href={"https://media.admin.qiktruck.co.za/" + m}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-preview"
                >
                  <img
                    src={"https://media.admin.qiktruck.co.za/" + m}
                    alt="doc"
                  />
                  <span>{m?.split("/")[1]}</span>
                </a>
              </>
            ))}
          </p>
          <div className="jobs-list">
            {paymentsordersquotations
              ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
              .map((o, index) => {
                return (
                  <div
                    key={o.orderid} //a paymentid only exists if the transaction is confirmed to be received by qiktruck. 1 via pasyfat and 1 via manual updates by admin on the dashboard.
                    className={
                      "payment-row " +
                      (o.paymentid && o.driverpaid ? " paid " : " ") +
                      (paymentList?.filter((f) => f.orderid === o.orderid)
                        .length > 0
                        ? " paying "
                        : " ") +
                      "" +
                      (driverFocus.orderid === o.orderid ||
                      fixPricingTrip.orderid === o.orderid
                        ? " preview "
                        : " ")
                    }
                    onClick={() => {
                      getOrderStatuses(o?.orderid);
                      getOrderConversations(o?.orderid);
                      setStartPaymentModal(o);
                    }}
                  >
                    {o?.orderid === driverFocus?.orderid && (
                      <div className="mdi mdi-eye float-right orange bold">
                        {" "}
                      </div>
                    )}
                    {o.paymentid && !o.driverpaid && (
                      <div className="mdi mdi-bank float-right blue bold">
                        {" "}
                      </div>
                    )}
                    {o.driverpaid !== 1 ? (
                      <div
                        onClick={() => {
                          let newArr = JSON.parse(JSON.stringify(paymentList));
                          const curr = newArr.findIndex(
                            (p) => p.orderid === o.orderid
                          );
                          if (curr < 0) newArr.push(o);
                          else newArr.splice(curr, 1);
                          setPaymentList(newArr);
                        }}
                        className="mdi mdi-checkbox-blank-circle red bold float-left collectiondate"
                      >
                        {moment(o.collectiondate).format("ddd D MMM YYYY")}
                      </div>
                    ) : (
                      <div className="mdi mdi-checkbox-blank-circle green bold float-left collectiondate">
                        {moment(o.collectiondate).format("ddd D MMM YYYY")}
                      </div>
                    )}
                    {o.paymentid?.length > 0 && (
                      <div className="mdi mdi-checkbox-blank-circle green bold float-left"></div>
                    )}
                    {(o?.tripdistance / 1000).toFixed(2)}km{" "}
                    <div
                      onClick={(e) =>
                        filterOrders({
                          ...ordersFilter,
                          filter: o.promocode,
                        })
                      }
                      className="promocode-n"
                    >
                      <img
                        src={
                          "https://media.qiktruck.co.za/bookfiles/" +
                          o?.promocode +
                          "-logo.png"
                        }
                        alt={o.promocode}
                        height="10"
                      />{" "}
                    </div>
                    <div style={{ float: "left" }}>
                      {o.firstname}, {o.lastname},
                    </div>
                    <div style={{ marginRight: "10px", fontSize: "10px" }}>
                      {o.orderid}
                    </div>
                    <div className="trip-prices -modal">{getPricesView(o)}</div>
                  </div>
                );
              })}
          </div>
          <div className="comments-list-parent">
            <div className="comments-list">
              <h6>comments - {orderconversations?.length}</h6>
              <p>{driverFocus?.description}</p>
              {orderconversations?.map((o, index) => (
                <span
                  key={o.orderconversationid + index}
                  className="comment-item"
                >
                  <b>
                    <img
                      src={`https://media.qiktruck.co.za/admins/${o.actorid}.png`}
                      alt="admin"
                      className="admin-profile-picture"
                      width={20}
                    />
                    {o.adminfirstname ||
                      o.customerfirstname ||
                      o.driverfirstname}{" "}
                    {o.adminlastname || o.customerlastname || o.driverlastname}
                  </b>
                  <br />
                  {moment(o.dateadded).format("ddd D MMM YY hh:mm:ss a")} <br />
                  {o.message}
                  {/* {o.message?.includes("started for file ::") && (
                    <a
                      href={`https://media.qiktruck.co.za/${
                        o.orderid
                      }/${o.message?.split("started for file ::")[1]?.trim()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>
                  )} */}
                </span>
              ))}
            </div>
            <div className="comments-list">
              <h6>status - {orderstatuses?.length}</h6>
              {orderstatuses?.map((o) => (
                <span key={o?.orderstatusid} className="comment-item">
                  <b>{o.status}</b>-
                  {moment(o.dateadded).format("ddd D MMM YY hh:mm:ss a")}-{" "}
                  {o.mobilenumber} - {o.firstname} - {o.packagetype}{" "}
                  <b>{o.licensenumber}</b> by {"> "}
                  {o.actor.split("-")[0]}
                </span>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <h4 className="confirm-driver-payment">
            <CurrencyFormat
              value={paymentList
                ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                .reduce(
                  (a, b) =>
                    a +
                    parseFloat(
                      getCalculations(b)?.driverpay / 100 ||
                        (b.sellingprice / 100) * (1 - b.servicefee) ||
                        0,
                      0
                    ),
                  0
                )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              decimalScale={2}
              className="float-left"
            />{" "}
            {paymentList.length > 0 &&
            cookie.load("hq-super-qt") === "superuser" ? (
              <Button
                color="danger"
                onClick={() => setDriverPaidOrders()}
                disabled={processingData}
              >
                Confirm Payment to QikTruck and Driver
              </Button>
            ) : (
              <Button color="danger" disabled={true}></Button>
            )}
            <CurrencyFormat
              value={paymentList
                ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                .reduce(
                  (a, b) =>
                    a +
                    parseFloat(
                      getCalculations(b)?.commission / 100 ||
                        (b.sellingprice * b.servicefee) / 100 ||
                        0,
                      0
                    ),
                  0
                )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              decimalScale={2}
              className="float-left"
            />{" "}
            <CurrencyFormat
              value={paymentList
                ?.filter((f) => f.vehicleid === driverFocus.vehicleid)
                .reduce(
                  (a, b) =>
                    a +
                    parseFloat(
                      getCalculations(b)?.vat / 100 ||
                        (b.servicefee * 0.15) / 100 ||
                        0,
                      0
                    ),
                  0
                )}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"R"}
              decimalScale={2}
              className="float-left"
            />{" "}
          </h4>
          <Button
            color="secondary"
            disabled={processingData}
            onClick={() => {
              setStartPaymentModal({});
            }}
          >
            close window
          </Button>
        </ModalFooter>
      </Modal>
      <section>
        <div>
          <Pagination aria-label="Page navigation example">
            {loading && <Spinner size="small" className={"small-spinner"} />}

            <input
              type="date"
              defaultValue={datefrom}
              onChange={(e) => {
                console.log("datefrom", e.target.value);
                setDateFrom(e.target.value);
                fetchOrdersQuotations(e.target.value);
              }}
            />
            <input
              placeholder="search"
              defaultValue={ordersFilter.filter}
              onChange={(e) =>
                filterOrders({
                  ...ordersFilter,
                  filter: e.target.value,
                })
              }
            />
            {/* calendar */}
            <button
              className={
                "reset-btn input " +
                (ordersFilter.filter.length > 0 ? "active" : "")
              }
              onClick={() => {
                filterOrders({
                  filter: "",
                });
                setPromocodeFilter("all");
                setPaymentList([]);
              }}
            >
              reset search
            </button>
            {vehicleList &&
              new Array(
                Math.ceil(
                  paymentsordersquotations
                    .filter((obj) => applyFilter(obj))
                    ?.filter((obj) => applyFilterForPromoCodes(obj)).length / 30
                )
              )
                .fill("")
                .map((e, index) => {
                  return (
                    <PaginationItem
                      key={index}
                      active={index === pPaginator}
                      onClick={() => {
                        setPPaginator(index);
                      }}
                    >
                      <PaginationLink>{index}</PaginationLink>
                    </PaginationItem>
                  );
                })}
          </Pagination>
          {
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <div className="stats-holder">
                      <CurrencyFormat
                        value={
                          paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) => a + parseFloat(b.sellingprice),
                              0
                            ) / 100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left"
                      />{" "}
                    </div>
                    <div className="stats-holder">
                      Received{" "}
                      <CurrencyFormat
                        value={
                          paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) =>
                                b.paymentid?.length > 0
                                  ? a + parseFloat(b.sellingprice)
                                  : a,
                              0
                            ) / 100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left "
                      />
                    </div>
                    <div className="stats-holder">
                      Received Fee{" "}
                      <CurrencyFormat
                        value={
                          paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) =>
                                b.paymentid?.length > 0
                                  ? a +
                                    parseFloat(b.sellingprice * b.servicefee)
                                  : a,
                              0
                            ) / 100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left green"
                      />{" "}
                    </div>
                    <div className="stats-holder">
                      Unpaid Fee{" "}
                      <CurrencyFormat
                        value={
                          paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) =>
                                b.paymentid?.length === 0 || !b.paymentid
                                  ? a +
                                    parseFloat(b.sellingprice * b.servicefee)
                                  : a,
                              0
                            ) / 100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left red"
                      />
                    </div>
                    <div className="stats-holder">
                      Unpaid{" "}
                      <CurrencyFormat
                        value={
                          paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) =>
                                b.paymentid?.length === 0 || !b.paymentid
                                  ? a + parseFloat(b.sellingprice)
                                  : a,
                              0
                            ) / 100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left red"
                      />
                    </div>
                    <div className="stats-holder">
                      Driver unpaid{" "}
                      <CurrencyFormat
                        value={paymentsordersquotations
                          .filter((obj) => applyFilter(obj))
                          ?.filter((obj) => applyFilterForPromoCodes(obj))
                          .reduce(
                            (a, b) =>
                              b.driverpaid !== 1
                                ? a +
                                  parseFloat(
                                    (b.sellingprice / 100) *
                                      (1 - b.servicefee) || 0,
                                    0
                                  )
                                : a,
                            0
                          )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        className="float-left red"
                      />
                    </div>
                    <div className="stats-holder">
                      <span className="allpaid">
                        Driver paid{" "}
                        <CurrencyFormat
                          value={paymentsordersquotations
                            .filter((obj) => applyFilter(obj))
                            ?.filter((obj) => applyFilterForPromoCodes(obj))
                            .reduce(
                              (a, b) =>
                                b.driverpaid === 1
                                  ? a +
                                    parseFloat(
                                      (b.sellingprice / 100) *
                                        (1 - b.servicefee) || 0,
                                      0
                                    )
                                  : a,
                              0
                            )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"R"}
                          decimalScale={2}
                          className="float-left"
                        />
                        {"  "}
                      </span>
                      {pPaginator * 30} to {pPaginator * 30 + 30} of{" "}
                      {paymentsordersquotations &&
                        paymentsordersquotations.length}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paymentsordersquotations &&
                  paymentsordersquotations
                    .filter((obj) => applyFilter(obj))
                    ?.filter((obj) => applyFilterForPromoCodes(obj))
                    .slice(pPaginator * 30, pPaginator * 30 + 30)
                    .map((o, index) => {
                      function extractMarketingData(data) {
                        try {
                          data = JSON.parse(
                            data?.split("<<navigator>>")[0].trim()
                          );
                          return {
                            vendor: data?.vendor,
                            browser: data?.userAgentData.brands[1].brand,
                            browserVersion:
                              data?.userAgentData?.brands[1].version,
                            mobile: data?.userAgentData?.mobile,
                            platform: data?.userAgentData.platform,
                            deviceMemory: data?.deviceMemory,
                            hardwareConcurrency: data?.hardwareConcurrency,
                            languages: data?.languages,
                            cookieEnabled: data?.cookieEnabled,
                            online: data?.onLine,
                          };
                        } catch (e) {}
                      }
                      const urlRegex =
                        /https?:\/\/(?:[-\w]+\.)?([-\w]+)\.\w+(?::\d+)?\/?(?:[^\s<]+)?/g;
                      let match;
                      let campaigns = {};
                      while ((match = urlRegex.exec(o?.source_)) !== null) {
                        match = match[0];
                        const url = new URL(match);
                        // Extract query parameters into an object
                        campaigns[url.hostname] = url.hostname;
                        const queryParams = {};
                        for (const [key, value] of url.searchParams.entries()) {
                          queryParams[key] = value;
                        }
                        campaigns = {
                          ...campaigns,
                          ...queryParams,
                          ...extractMarketingData(o?.source_),
                        };
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <div
                              key={o.quoteid}
                              className={
                                "payment-row " +
                                (o.paymentid && o.driverpaid ? " paid " : " ") +
                                (paymentList?.filter(
                                  (f) => f.orderid === o.orderid
                                ).length > 0
                                  ? " paying "
                                  : " ") +
                                (fixPricingTrip.orderid === o.orderid
                                  ? " preview "
                                  : " ")
                              }
                            >
                              {o?.orderid === driverFocus?.orderid && (
                                <i className="mdi mdi-eye float-right orange bold">
                                  {" "}
                                </i>
                              )}
                              {o.paymentid && !o.driverpaid && (
                                <div
                                  onClick={() => {
                                    filterOrders({
                                      ...ordersFilter,
                                      payfast: true,
                                    });
                                  }}
                                  className="mdi mdi-bank float-right blue bold"
                                >
                                  {" "}
                                </div>
                              )}
                              {o.driverpaid !== 1 ? ( //means qiktruck has paid driver if driverpaid = 1
                                <div
                                  onClick={() => {
                                    filterOrders({
                                      ...ordersFilter,
                                      nopayment: true,
                                    });
                                  }}
                                  className="mdi mdi-checkbox-blank-circle red bold"
                                ></div>
                              ) : (
                                <div className="mdi mdi-checkbox-blank-circle green bold float-left"></div>
                              )}
                              <div
                                onClick={() => {
                                  let newArr = JSON.parse(
                                    JSON.stringify(paymentList)
                                  );
                                  const curr = newArr.findIndex(
                                    (p) => p.orderid === o.orderid
                                  );
                                  if (curr < 0) newArr.push(o);
                                  else newArr.splice(curr, 1);
                                  setPaymentList(newArr);
                                }}
                              >
                                {o.orderid.split("-")[0]}
                              </div>
                              <div
                                onClick={(e) =>
                                  filterOrders({
                                    ...ordersFilter,
                                    filter: o.collectiondate,
                                  })
                                }
                              >
                                {" - "}
                                {moment(o?.collectiondate).format(
                                  "dd D MMM YY"
                                )}{" "}
                              </div>
                              <div
                                onClick={(e) =>
                                  filterOrders({
                                    ...ordersFilter,
                                    filter: o.promocode,
                                  })
                                }
                                className="promocode"
                              >
                                <img
                                  src={
                                    "https://media.qiktruck.co.za/bookfiles/" +
                                    o?.promocode +
                                    "-logo.png"
                                  }
                                  alt={o.promocode}
                                  height="15"
                                />{" "}
                              </div>
                              <div
                                onClick={(e) =>
                                  filterOrders({
                                    ...ordersFilter,
                                    filter: o.firstname,
                                  })
                                }
                                className="customer-names"
                              >
                                {o.firstname?.toLowerCase()},{" "}
                                {o.lastname?.toLowerCase()},{" "}
                                <i
                                  className="mdi mdi-email"
                                  onClick={(e) =>
                                    filterOrders({
                                      ...ordersFilter,
                                      filter: o.email,
                                    })
                                  }
                                ></i>
                                <i
                                  className="mdi mdi-phone"
                                  onClick={(e) =>
                                    filterOrders({
                                      ...ordersFilter,
                                      filter: o.mobilenumber,
                                    })
                                  }
                                ></i>
                              </div>
                              <div
                                onClick={(e) =>
                                  filterOrders({
                                    ...ordersFilter,
                                    filter: o.driveridnumber,
                                  })
                                }
                                className="driver-names"
                              >
                                <div className="spanner">
                                  {o.driverfirstname} {o.driverlastname},
                                  {o.licenseplatenumber},{" "}
                                </div>
                                <img
                                  src={getImage(o?.trucktype)}
                                  alt=""
                                  height="25"
                                  onClick={(e) => {
                                    setStartPaymentModal(o);
                                    getOrderStatuses(o?.orderid);
                                    getOrderConversations(o?.orderid);
                                    // if (
                                    //   paymentList?.filter(
                                    //     (f) => f.orderid === o.orderid
                                    //   ).length === 0
                                    // ) {
                                    //   let newArr = JSON.parse(
                                    //     JSON.stringify(paymentList)
                                    //   );
                                    //   const curr = newArr.findIndex(
                                    //     (p) => p.orderid === o.orderid
                                    //   );
                                    //   if (curr < 0) newArr.push(o);
                                    //   else newArr.splice(curr, 1);
                                    //   setPaymentList(newArr);
                                    // }

                                    // vehicleProfile(o?.vehicleid);
                                  }}
                                />
                                {!getCalculations(o).valid &&
                                  o?.cpkcents !== null &&
                                  o?.promocode && (
                                    <i className="mdi mdi-alert red show-hidden-message">
                                      <div className="hidden-message">
                                        The sellingprice presented{" "}
                                        <b>
                                          {(o?.sellingprice / 100).toFixed(2)}
                                        </b>{" "}
                                        does not match the KM x CPK + VAT
                                        calculation of{" "}
                                        <b>
                                          {(
                                            getCalculations(o)?.sellingprice /
                                            100
                                          )?.toFixed(2)}
                                        </b>{" "}
                                        with CPK =
                                        <b>{(o?.cpkcents / 100).toFixed(2)}</b>
                                      </div>
                                    </i>
                                  )}
                                {(o?.tripdistance / 1000).toFixed(2)}km
                              </div>
                              <div className="trip-prices">
                                {getPricesView(o)}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          }
        </div>
      </section>
    </div>
  );
}

export default memo(MyComponent);
