import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import "./assets/css/materialdesignicons.min.css";
import ProtectedSection from "./components/common/ProtectedSection";
import ScrollToTop from "./components/common/scrollToTop";
import routes from "./routes";
import "./theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <Switch>
            {routes.map((route, idx) => (
              <Route
                path={route.path}
                component={() => (
                  <ProtectedSection>{<route.component />}</ProtectedSection>
                )}
                key={idx}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
