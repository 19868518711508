import React, { Component } from "react";
import cookie from "react-cookies";
import { Navbar, NavbarBrand } from "reactstrap";
import logolight from "../../assets/images/logo-light.png";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  isStringInCurrentPath = (searchString) => {
    const currentPath = window.location.pathname;
    return currentPath.includes(searchString);
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    return (
      <React.Fragment>
        <Navbar expand="sm" className={"navbar-custom " + this.props.navClass}>
          <NavbarBrand className="logo" href="/vehicles">
            <img src={logolight} alt="" className="logo-light" />{" "}
          </NavbarBrand>

          {cookie.load("hq-id-qt") && (
            <>
              <a
                href="/payments"
                className={
                  this.isStringInCurrentPath("payments") ? "active" : ""
                }
              >
                {" "}
                Payments
              </a>{" "}
            </>
          )}
          {cookie.load("hq-id-qt") && (
            <div className="navbar-right">
              <button
                onClick={() => {
                  cookie.remove("hq-id-qt");
                  cookie.remove("adminName");
                  setTimeout(() => {
                    cookie.remove("hq-id-qt");
                    cookie.remove("adminName");
                    window.location.reload();
                  }, 3000);
                }}
              >
                {cookie.load("adminName")?.substring(0, 2)}
              </button>
            </div>
          )}
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
