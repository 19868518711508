import React, { Component } from "react";
import cookie from "react-cookies";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "../../pages/landing/section";

class ProtectedSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "hq-id-qt": cookie.load("hq-id-qt"),
    };
  }

  render() {
    return (
      <>
        <NavbarPage />
        {typeof this.state["hq-id-qt"] !== "undefined" ? (
          this.props.children
        ) : (
          <Section />
        )}
      </>
    );
  }
}

export default ProtectedSection;
