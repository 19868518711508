import React, { Component } from "react";
import Section from "./section";

class Index3 extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Importing section */}
        <Section />
      </React.Fragment>
    );
  }
}

export default Index3;
